<template>
  <div class="main-header">
    <div class="main-header__background" />
    <wrapper class="main-header__wrapper">
      <div>
        <label class="main-header__module">
          {{ module }}
        </label>
        <h1 class="main-header__title">
          {{ pageTitle }}
        </h1>
        <p class="main-header__description">
          {{ description }}
        </p>
        <img
          v-if="headerImage"
          class="main-header__img"
          :src="headerImage"
        >
        <slot />
      </div>
      <slot name="header" />
    </wrapper>
  </div>
</template>

<script>
export default {
  name: 'MainHeader',
  props: {
    module: {
      type: String,
      required: true,
    },
    pageTitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    headerImage: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="sass" scoped>
$image-max-width: 360px

.main-header
  position: relative
  color: $color-white
  background: linear-gradient(106.57deg, #2B4A99 2.65%, #2B5999 98.74%)
  padding-top: $size-xs

  &__wrapper
    display: flex
    flex-direction: row
    justify-content: space-between

  +mq-s--mf
    padding-top: $size-m

  ::v-deep .sas-wrapper
    position: relative

  &__module
    font-size: $font-size-s
    font-weight: $font-weight-bold
    text-transform: uppercase
    letter-spacing: 0.4px
    color: #F8CB7F !important
    padding-top: $size-xs

  &__title
    margin-top: $size-s

  &__description
    line-height: $size-m
    letter-spacing: 0.3px
    color: $color-white
    margin-top: $size-xs
    margin-bottom: $size-l
    max-width: 400px

    +mq-l--mf
      max-width: 450px

  &__img
    position: absolute
    top: 0
    right: 0
    max-width: $image-max-width
    visibility: hidden
    z-index: 1

    +mq-m--mf
      visibility: visible

  &__background

    &:before,
    &:after
      position: absolute
      top: 0
      left: 0
      content: ''
      display: block
      height: 100%
      width: 100%
      z-index: 0

    &:before
      background: url('~@/assets/images/left.svg') no-repeat
      background-position: 0 100%

    &:after
      background: url('~@/assets/images/right.svg') no-repeat
      background-position: 100% 0
</style>
