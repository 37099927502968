/**
 * A module that exports methods for integration with Typeform.
 * @module typeform
 *
 * For further documentation:
 * [Embed SDK]{@link https://developer.typeform.com/embed/}
 * [NPM Page]{@link https://www.npmjs.com/package/@typeform/embed}
 */
const BASE_URL = 'https://pesquisasas.typeform.com/to'

/**
   * Transforms an object into URL params.
   *
   * @param {object} params - Object to be transformed.
   */
const stringifyHiddenFields = (params) => new URLSearchParams(params)

export default {
  /**
     * Appends Typeform Embed script into the index.html body,
     * creating the global window.typeformEmbed object attribute.
     * Only needs to be called once, ideally on the project root file.
     *
     * @param {object} onReady - Callback after the CDN is installed.
     */
  install({ onReady, id = 'typeform-embed' }) {
    const hasScriptLoaded = document.getElementById(id) !== null

    if (hasScriptLoaded) {
      if (typeof onReady === 'function') {
        onReady()
      }

      return
    }

    const typeform = document.createElement('script')

    if (typeof onReady === 'function') {
      typeform.addEventListener('load', onReady)
    }

    typeform.setAttribute('id', id)
    typeform.setAttribute('src', 'https://embed.typeform.com/embed.js')

    document.body.appendChild(typeform)
  },
  /**
     * Creates a Typeform widget with a DOM element as container.
     *
     * @param {node} container - Container element reference.
     * @param {string} formId - Form identifier, e.g: p4EzLacv.
     * @param {object} hiddenFields - User related data, e.g: id, name.
     * @param {object} options - Form layout and behavior configurations.
     *
     * For details on the options object properties, check @typeform/embed docs.
     *
     * @example
     * const container = document.getElementById('typeform-widget-container')
     * typeform.createWidget(container, 'p4EzLacv')
     *
     * @returns {object} Typeform Embed form instance.
     */
  createWidget({
    container,
    formId,
    hiddenFields,
    options,
  }) {
    const url = `${BASE_URL}/${formId}?${stringifyHiddenFields(hiddenFields)}`

    return window.typeformEmbed.makeWidget(container, url, { ...options })
  },
  /**
     * Creates a Typeform popup with no need of a DOM element container.
     *
     * @param {string} formId - Form identifier, e.g: p4EzLacv.
     * @param {object} hiddenFields - User related data, e.g: id, name.
     * @param {object} options - Form layout and behavior configurations.
     *
     * For details on the options object properties, check @typeform/embed docs.
     *
     * @example
     * const popup = typeform.createPopup('p4EzLacv')
     * popup.open()
     * popup.close()
     *
     * @returns {object} Typeform Embed form instance.
     */
  createPopup({ formId, hiddenFields, options }) {
    const url = `${BASE_URL}/${formId}?${stringifyHiddenFields(hiddenFields)}`

    return window.typeformEmbed.makePopup(url, { ...options })
  },
}
